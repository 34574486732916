import { RoutePath } from '../../../1_shared/config/routes';

import { IConfHeadersLink } from './interfaces/IConfHeadersLink';

export const headerLinks: IConfHeadersLink[] = [
  {
    label: 'Каждому',
    url: '/',
    isAccordion: true,
    children: [
      {
        label: 'Специалисты для меня',
        url: RoutePath.SPECIALISTS,
        isAccordion: false,
      },
      {
        label: 'Специалисты для клиента',
        url: RoutePath.CLIENT_MAIN,
        isAccordion: false,
      },
    ],
  },
  {
    label: 'Психологам',
    url: '/',
    isAccordion: true,
    children: [
      {
        label: 'Стать лучше как психолог',
        url: RoutePath.REACT_BETTER_PSYCHOLOGICAL,
        isAccordion: false,
      },
    ],
  },
  {
    label: 'Компаниям',
    url: 'https://old.dotherapy.ru/b2b',
    isAccordion: false,
  },
  {
    label: '15 мин консультация',
    url: '#application',
    isAccordion: false,
  },
  {
    label: 'О нас',
    url: '/#about',
    isAccordion: false,
  },
  {
    label: 'Блог',
    url: 'https://old.dotherapy.ru/blog',
    isAccordion: false,
  },
];

export const headerLinksUZ: IConfHeadersLink[] = [
  {
    label: 'Специалисты',
    url: RoutePath.SPECIALISTS,
    isAccordion: false,
  },
  {
    label: 'Персональная консультация',
    url: '#application',
    isAccordion: false,
  },
  {
    label: 'О нас',
    url: '/#about',
    isAccordion: false,
  },
];
