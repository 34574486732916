import 'app/styles/global/global.scss';
import './psychologists-near.scss';

const PsychologistsNearLearningPath = () => (
<section className="psychologists-near">
  <div className="container">
    <div className="psychologists-near__inner">
      <div className="psychologists-near__content">
        <h2 className="psychologists-near__title section-title">Поможем выбрать траекторию обучения</h2>
        <p className="psychologists-near__subtitle">Индивидуальный подбор специалистов<br /> и курсов под ваши запросы и условия</p>
        <a href="/" className="psychologists-near__link btn btn--white">Оставить заявку</a>
      </div>
    </div>
  </div>
</section>
);

export default PsychologistsNearLearningPath;