import { useMemo } from 'react';
import { Route, Routes as RoutesPath } from 'react-router';

import useInterceptors from '../../../1_shared/api/hooks/useInterceptors';
import { Roles } from '../../../1_shared/config/interfaces/Roles';
import { isRegionUz } from '../../../1_shared/helpers/RegionHelpers/isRegionUz';
import { routerConfig, routesConfigUZ } from '../../config/router';
import NotificationContext from '../lib/context/NotificationContext';
import { useAuthContext } from '../lib/hooks/useAuthContext';

const routesConfigBase = isRegionUz() ? routesConfigUZ : routerConfig;

const Routes = () => {
  const { role } = useAuthContext();
  const { contextHolder, messageApi } = useInterceptors();

  const router = useMemo(
    () =>
      routesConfigBase
        .filter(
          route =>
            route.roles.includes(role) ||
            route.roles.includes(Roles.Unauthorized),
        )
        .map(route => ({
          path: route.path,
          element: route.element,
        })),
    [role],
  );

  return (
    <section>
      <NotificationContext.Provider value={{ messageApi }}>
        <RoutesPath>
          {router.map(route => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
        </RoutesPath>
        {contextHolder}
      </NotificationContext.Provider>
    </section>
  );
};

export default Routes;
