import { ReactElement } from 'react';

import { Breadcrumbs, Typography } from '1_shared/ui';
import { Slots, SpecialistReserveSlot } from '3_features';

import { eduConfig } from '../../../1_shared/config/eduConfig';
import { format } from '../../../1_shared/config/format';

import { ISpecialistEntitiesCardProps } from './interfaces/ISpecialistEntitiesCardProps';

import styles from './SpecialistPersonCardData.module.scss';

const SpecialistPersonCardData = ({
  specialist,
  value,
  onChange,
}: ISpecialistEntitiesCardProps): ReactElement => {
  const breadCrumbs = [
    {
      path: '/',
      title: 'DOTHERAPY',
    },
    {
      path: '/specialists',
      title: 'КАЖДОМУ',
    },
    {
      path: '/specialists/1',
      title: 'СПЕЦИАЛИСТЫ ДЛЯ МЕНЯ',
    },
  ];

  return (
    <div className={styles.root}>
      <div className={styles.nameAndSpec}>
        <Breadcrumbs routes={breadCrumbs} />
        <div className={styles.specialistPersonInfo}>
          <div className={styles.nameBlock}>
            <Typography className={styles.firstName} type="title">
              {specialist.firstName}
            </Typography>
            <Typography className={styles.secondName} type="title">
              {specialist.secondName}
            </Typography>
          </div>
          <SpecialistReserveSlot
            slot={value}
            specialistId={specialist.id}
            spec={specialist}
          />
        </div>

        <div className={styles.specFirst}>
          <div className={styles.specLineItem}>
            <Typography type="description">ФОРМАТ</Typography>
            <Typography>{format[specialist.format]}</Typography>
          </div>
          <div className={styles.specLineItem}>
            <Typography type="description">ОПЫТ</Typography>
            <Typography>{specialist.yearsOfExperience}</Typography>
          </div>
          <div className={styles.specLineItem}>
            <Typography type="description">СПЕЦИАЛИЗАЦИЯ</Typography>
            <div>
              {specialist.specialities?.map((spec, index) => (
                <Typography key={spec}>
                  {`${spec}${index !== specialist.specialities.length - 1 ? ', ' : '.'}`}
                </Typography>
              ))}
            </div>
          </div>
        </div>
        <div className={styles.specSecond}>
          <div className={styles.specLineItem}>
            <Typography type="description">ОБРАЗОВАНИЕ</Typography>
            <Typography>{eduConfig[specialist.educationType]}</Typography>
          </div>
          <div className={styles.specLineItem}>
            <Typography type="description">МОДАЛЬНОСТИ</Typography>
            <div>
              {specialist.workMethods?.map((spec, index) => (
                <Typography key={spec}>
                  {spec}
                  {index !== specialist.workMethods.length - 1 ? ', ' : '. '}
                </Typography>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Slots
        slots={specialist.slots}
        spec={specialist}
        onChange={onChange}
        value={value}
      />
    </div>
  );
};

export default SpecialistPersonCardData;
