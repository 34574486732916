import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import {
  ISpecialistListFilters,
  ISpecialistListFiltersQuery,
} from '1_shared/config/interfaces/ISpecialistListFilters';
import {
  Breadcrumbs,
  Content,
  Faq,
  Feedback,
  Footer,
  Page,
  Statistics,
  ToRead,
  TryGroup,
  Typography,
} from '1_shared/ui';
import { Header, VideoStory } from '4_widgets';

import { RoutePath } from '../../../1_shared/config/routes';
import { isRegionUz } from '../../../1_shared/helpers/RegionHelpers/isRegionUz';
import { formatTitle } from '../../../1_shared/lib/helpers/formatText';
import SpecialistFiltersUZ from '../../../4_widgets/SpecialistFiltersUZ/ui/SpecialistFiltersUZ';
import { ILocationSpec } from '../model/service/interfaces/ILocationSpec';
import useGetSpecialistList from '../model/service/useGetSpecialistList';

import SpecialistListByRegion from './SpecialistListByRegion/SpecialistListByRegion';

import styles from './SpecialistsPage.module.scss';

const breadCrumbs = [
  {
    title: (
      <Link to={RoutePath.MAIN}>
        <Typography type="description">DOTHERAPY</Typography>
      </Link>
    ),
  },
  {
    title: (
      <Link to={RoutePath.SPECIALISTS}>
        <Typography type="description">КАЖДОМУ</Typography>
      </Link>
    ),
  },
  {
    title: (
      <Link to={RoutePath.SPECIALISTS}>
        <Typography type="description">СПЕЦИАЛИСТЫ ДЛЯ МЕНЯ</Typography>
      </Link>
    ),
  },
];

const breadCrumbsUZ = [
  {
    title: (
      <Link to={RoutePath.MAIN}>
        <Typography type="description">PSYCHOLGI</Typography>
      </Link>
    ),
  },
  {
    title: (
      <Link to={RoutePath.SPECIALISTS}>
        <Typography type="description">СПЕЦИАЛИСТЫ ДЛЯ МЕНЯ</Typography>
      </Link>
    ),
  },
];

const SpecialistsPage: FC = () => {
  const location = useLocation();
  const state = location.state as ILocationSpec;

  const methods = useFormContext<ISpecialistListFilters>();

  const { specList, setFilters, viewMore, total, totalShowedSpecs } =
    useGetSpecialistList();

  const handleFiltersChange = (filters: ISpecialistListFiltersQuery) => {
    setFilters(filters.filter);
  };

  useEffect(() => {
    if (state?.filters) {
      methods.reset(state.filters);
      setFilters(state.filters);
      location.state = {};
      window.history.replaceState({}, '');
    }
  }, [methods, setFilters, state?.filters]);

  const headerText = specList?.first?.length
    ? `${formatTitle(total, [`Вам поможет этот отличный специалист`, `Вам поможет эти ${total} отличных специалиста`, `Вам поможет эти ${total} отличных специалиста`, `Вам помогут эти ${total} отличных специалистов`])}`
    : 'Специалисты не найдены';

  const headerTextUz = 'Лучшие специалисты готовы вам помочь';

  return (
    <Page>
      <Header />
      <Content wrapperClass={cn(styles.contentUz)}>
        <Breadcrumbs items={isRegionUz() ? breadCrumbsUZ : breadCrumbs} />
        <div className={styles.headerText}>
          <Typography type="h2">
            {isRegionUz() ? headerTextUz : headerText}
          </Typography>
        </div>
        <div className={cn({ [styles.catalogWrapperUz]: isRegionUz() })}>
          {isRegionUz() && (
            <div className={styles.filtersUZ}>
              <SpecialistFiltersUZ onChange={handleFiltersChange} />
            </div>
          )}
          {/** TODO: вынести в отдельный блок формирование листа специалистов согласно региону *126 */}
          <SpecialistListByRegion
            handleFiltersChange={handleFiltersChange}
            specList={specList}
            totalShowedSpecs={totalShowedSpecs}
            total={total}
            viewMore={viewMore}
          />
          {/** TODO: *126 */}
        </div>
      </Content>
      {!isRegionUz() && <VideoStory />}
      {!isRegionUz() && <TryGroup />}
      <ToRead />
      <Feedback />
      <Statistics />
      <Faq />
      <Footer />
    </Page>
  );
};

export default SpecialistsPage;
