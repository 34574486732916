import { ReactElement } from 'react';

import { Typography } from '1_shared/ui';
import { KeyThemes } from '3_features';

import { IHelpChooseProps } from './interfaces/IHelpChooseProps';

import styles from './HelpChoose.module.scss';

const HelpChoose = ({ onChange }: IHelpChooseProps): ReactElement => (
  <div className={styles.root}>
    <KeyThemes onChange={onChange} />
    <div className={styles.onlineCons}>
      <Typography type="title">
        Можем проконсультировать индивидуально
      </Typography>
      <Typography>
        Спишемся в телеграме, наши специалисты
        <br />
        помогают за 10 минут
      </Typography>
      <div className={styles.btnWrapper}>
        <a
          href="https://t.me/do_therapy"
          className="btn btn--violet"
          type="primary"
        >
          ПЕРЕЙТИ В ТЕЛЕГРАММ
        </a>
      </div>
    </div>
  </div>
);

export default HelpChoose;
