import { ReactElement } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import SpecialistPersonCardData from '2_entities/SpecialistPersonCardData/ui/SpecialistPersonCardData';
import { PreviewListImage } from '3_features';

import { RoutePath } from '../../../1_shared/config/routes';
import { isRegionUz } from '../../../1_shared/helpers/RegionHelpers/isRegionUz';
import SpecialistPersonCardDataUz from '../../../2_entities/SpecialistPersonCardData/ui/SpecialistPersonCardDataUz';
import { configMedia } from '../../SpecialistCard/ui/config/configMedia';
import { ISpecialistTimeForm } from '../../SpecialistCard/ui/interfaces/ISpecialistTimeForm';

import { ISpecPersonCards } from './interfaces/ISpecPersonCards';

import styles from './SpecialistPersonCard.module.scss';

const SpecialistPersonCard = ({ spec }: ISpecPersonCards): ReactElement => {
  const { control, handleSubmit } = useForm<ISpecialistTimeForm>();
  const navigate = useNavigate();

  const onSubmit = (data: ISpecialistTimeForm) => {
    navigate(RoutePath.ORDER, { state: { id: spec.id, slot: data.slot } });
  };
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      key={spec.id}
      className={styles.root}
    >
      <PreviewListImage
        media={
          spec.mediaContentResponse?.length
            ? spec.mediaContentResponse
            : [configMedia]
        }
      />
      <Controller
        name="slot"
        control={control}
        render={({ field: { value, onChange } }) =>
          isRegionUz() ? (
            <SpecialistPersonCardDataUz
              specialist={spec}
              onChange={onChange}
              value={value}
            />
          ) : (
            <SpecialistPersonCardData
              specialist={spec}
              onChange={onChange}
              value={value}
            />
          )
        }
      />
    </form>
  );
};

export default SpecialistPersonCard;
