import { Mousewheel } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import Program1Img from '1_shared/assets/images/programs/program-1@1x.png';
import Program3Img from '1_shared/assets/images/programs/program-3@1x.png';
import Program4Img from '1_shared/assets/images/programs/program-4@1x.png';
import Program5Img from '1_shared/assets/images/programs/program-5@1x.png';

import 'swiper/scss';
import 'app/styles/global/global.scss';
import './programs.scss';

const Programs = () => (
  <section className="programs">
    <div className="programs__container container">
      <h2 className="programs__title section-title">Наши программы</h2>
    </div>
    <Swiper
      spaceBetween={20}
      slidesPerView="auto"
      centerInsufficientSlides
      breakpoints={{
        1024: {
          spaceBetween: 40,
        },
      }}
      mousewheel={{ enabled: true, releaseOnEdges: true }}
      modules={[Mousewheel]}
      centeredSlidesBounds
      className="programs__slider"
      wrapperClass="programs__items"
      id="programsSlider"
      setWrapperSize
    >
      <SwiperSlide className="programs__item swiper-slide">
        <a href="https://old.dotherapy.ru/art" className="programs__link">
          <div className="programs__image">
            <img
              src={Program1Img}
              alt="Иммерсивные учебные сессии по арт-терапии"
            />
          </div>
          <div className="programs__content">
            <div className="programs__tags">
              <span>онлайн</span>
              <span>группа</span>
              <span>2 месяца</span>
            </div>
            <h3 className="programs__item-title">
              Иммерсивные учебные сессии по арт-терапии
            </h3>
          </div>
        </a>
      </SwiperSlide>

      <SwiperSlide className="programs__item swiper-slide">
        <a
          href="https://old.dotherapy.ru/groupanalysis"
          className="programs__link"
        >
          <div className="programs__image">
            <img src={Program5Img} alt="Групп-анализ" />
          </div>
          <div className="programs__content">
            <div className="programs__tags">
              <span>онлайн</span>
              <span>группа</span>
              <span>2 года</span>
            </div>
            <h3 className="programs__item-title">Групп-анализ</h3>
          </div>
        </a>
      </SwiperSlide>

      <SwiperSlide className="programs__item swiper-slide">
        <a
          href="https://old.dotherapy.ru/modalnosti"
          className="programs__link"
        >
          <div className="programs__image">
            <img src={Program3Img} alt="4 модальности психологии" />
          </div>
          <div className="programs__content">
            <div className="programs__tags">
              <span>онлайн</span>
              <span>группа</span>
            </div>
            <h3 className="programs__item-title">4 модальности психологии</h3>
          </div>
        </a>
      </SwiperSlide>

      <SwiperSlide className="programs__item swiper-slide">
        <a
          href="https://old.dotherapy.ru/support_point"
          className="programs__link"
        >
          <div className="programs__image">
            <img src={Program4Img} alt="Стоп негатив! Группы дерефлексии" />
          </div>
          <div className="programs__content">
            <div className="programs__tags">
              <span>онлайн</span>
              <span>группа</span>
            </div>
            <h3 className="programs__item-title">
              Стоп негатив! Группы дерефлексии
            </h3>
          </div>
        </a>
      </SwiperSlide>
    </Swiper>
  </section>
);

export default Programs;
