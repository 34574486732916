import { ReactElement } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { ISpecialistListFilters } from '../../../../1_shared/config/interfaces';
import { Select } from '../../../../1_shared/ui';

import styles from '../SpecialistFilters.module.scss';

const FilterSelect = ({
  name,
  options,
  defaultValue,
  handleFormSubmit,
}: {
  name: 'experienceSortOrder' | 'specShowType';
  options: { value: string | number; label: string }[];
  defaultValue: string | number;
  handleFormSubmit: (data: ISpecialistListFilters) => void;
}): ReactElement => {
  const { control, handleSubmit } = useFormContext<ISpecialistListFilters>();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <Select
          className={styles.sortSelect}
          onChange={val => {
            onChange(val);
            handleSubmit(handleFormSubmit)();
          }}
          value={value}
          allowClear={false}
          options={options}
          defaultValue={defaultValue}
        />
      )}
    />
  );
};

export default FilterSelect;
