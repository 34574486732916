const wordDeclination = (total: number, words: string[]) => {
  /*
   Порядок слов для массива words:
     0. Слово под индексом 0 для единственного значения
     1. Слово под 1 индексом для чисел заканчивающихся на один, но не на 11.
     2. Слово под индексом 2 для чисел заканчивающихся на числа от 2-4, но где последние 2 цифры не
      находятся в диапазоне от 10 до 20 включительно.
     3. Слово под индексом 3 для всех оставшихся чисел.
  */
  if (total === 1) {
    return words[0];
  }
  if (total % 10 === 1 && total % 100 !== 11) {
    return words[1];
  }
  if (
    total % 10 >= 2 &&
    total % 10 <= 4 &&
    (total % 100 < 10 || total % 100 >= 20)
  ) {
    return words[2];
  }
  return words[3];
};

const formatTotalAmount = (total: number) => {
  if (total.toString().length < 4) return total;
  let res = total.toString().split('').reverse();
  res = res.map((el, index) => (index % 3 === 0 ? `${el} ` : `${el}`));
  return res.reverse().join('');
};

export const formatTitle = (total: number, words: string[]) =>
  `${wordDeclination(total, words)}`;

export { formatTotalAmount };
