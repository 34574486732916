import React, { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { sortBy } from 'lodash';

import { Button, Typography } from '1_shared/ui';
import { ISpecPersonCards } from '4_widgets/SpecialistPersonCard/ui/interfaces/ISpecPersonCards';

import { isRegionUz } from '../../../1_shared/helpers/RegionHelpers/isRegionUz';

import { IExpOpenConfig } from './interfaces/IExpOpenConfig';

import styles from './SpecialistExperienceData.module.scss';

const SpecialistExperienceData: React.FC<ISpecPersonCards> = ({ spec }) => {
  const [openExp, setOpenExp] = useState<IExpOpenConfig>({
    exp: false,
    jobs: false,
    prof: false,
  });

  const jobs = useMemo(
    () => sortBy(spec.jobExperiences, job => job.dateFrom).reverse(),
    [spec.jobExperiences],
  );

  const edu = useMemo(
    () => [
      ...sortBy(spec.educations, ed => ed.dateFrom),
      ...sortBy(spec.professionalRetrainings, ed => ed.issuedAt),
    ],
    [spec.educations, spec.professionalRetrainings],
  );

  return (
    <div className={styles.root}>
      {!!jobs.length && (
        <div className={styles.card}>
          <Typography type="textM">Опыт</Typography>
          <div className={styles.wrapperData}>
            <div key={jobs[0]?.position} className={styles.gridBlock}>
              <Typography type="description" className={styles.year}>
                {dayjs(jobs[0]?.dateFrom).format('YYYY')}
              </Typography>
              <div className={styles.expWrapper}>
                <Typography>
                  {`${jobs[0]?.city}, ${jobs[0]?.company}, ${jobs[0]?.position}`}
                </Typography>
                {spec.jobExperiences.length > 1 && !openExp.jobs && (
                  <Button
                    type="secondary"
                    onClick={() =>
                      setOpenExp({
                        exp: false,
                        jobs: true,
                        prof: false,
                      })
                    }
                  >
                    {jobs.length - 1 === 1
                      ? `ПОДРОБНЕЕ`
                      : `+${jobs.length - 1}`}
                  </Button>
                )}
              </div>
            </div>
            {openExp.jobs &&
              [...jobs].slice(1).map(job => (
                <div key={job.position} className={styles.gridBlock}>
                  <Typography type="description" className={styles.year}>
                    {dayjs(job.dateFrom).format('YYYY')}
                  </Typography>
                  <Typography>
                    {`${job.city}, ${job.company}, ${job.position}`}
                  </Typography>
                </div>
              ))}
            {openExp.jobs && (
              <Button
                type="secondary"
                className={styles.btnClose}
                onClick={() =>
                  setOpenExp({
                    exp: false,
                    jobs: false,
                    prof: false,
                  })
                }
              >
                СВЕРНУТЬ
              </Button>
            )}
          </div>
        </div>
      )}

      {!!edu.length && (
        <div className={styles.card}>
          <Typography type="textM">Образование</Typography>
          <div className={styles.wrapperData}>
            <div
              key={'university' in edu[0] ? edu[0].university : edu[0].title}
              className={styles.gridBlock}
            >
              <Typography type="description" className={styles.year}>
                {('issuedAt' in edu[0] && edu[0].issuedAt) ||
                'dateFrom' in edu[0]
                  ? dayjs(
                      'dateFrom' in edu[0] ? edu[0].dateFrom : edu[0].issuedAt,
                    ).format('YYYY')
                  : '-'}
              </Typography>
              <div className={styles.expWrapper}>
                <Typography>
                  {`${'university' in edu[0] ? edu[0].university : edu[0].title}, ${'specialization' in edu[0] ? edu[0].specialization : edu[0].issuer}`}
                </Typography>
                {edu.length > 1 && !openExp.exp && (
                  <Button
                    type="secondary"
                    onClick={() =>
                      setOpenExp({
                        exp: true,
                        jobs: false,
                        prof: false,
                      })
                    }
                  >
                    {edu.length === 1
                      ? `${isRegionUz() ? 'ОБ ЭКСПЕРТЕ' : 'ПОДРОБНЕЕ'}`
                      : `+${edu.length - 1}`}
                  </Button>
                )}
              </div>
            </div>
            {openExp.exp &&
              [...edu].slice(1).map(ed => (
                <div
                  key={'university' in ed ? ed.university : ed.title}
                  className={styles.gridBlock}
                >
                  <Typography type="description" className={styles.year}>
                    {('issuedAt' in ed && ed.issuedAt) || 'dateFrom' in ed
                      ? dayjs(
                          'dateFrom' in ed ? ed.dateFrom : ed.issuedAt,
                        ).format('YYYY')
                      : '-'}
                  </Typography>
                  <Typography>
                    {`${'university' in ed ? ed.university : ed.title}, ${'specialization' in ed ? ed.specialization : ed.issuer}`}
                  </Typography>
                </div>
              ))}
            {openExp.exp && (
              <Button
                type="secondary"
                className={styles.btnClose}
                onClick={() =>
                  setOpenExp({
                    exp: false,
                    jobs: false,
                    prof: false,
                  })
                }
              >
                СВЕРНУТЬ
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default SpecialistExperienceData;
