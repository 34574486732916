import DotBannerMp4 from '1_shared/assets/video/dot_banner.mp4';

import 'app/styles/global/global.scss';
import './survey.scss';

const SurveyPsy = () => (
  <section className="survey survey--psy">
    <div className="container survey__container">
      <div className="survey__inner">
        <div className="survey__heading">
          <h2 className="survey__title section-title">
            Не уверены, что подходит именно вам?
          </h2>
          <p className="survey__subtitle">
            Поможем понять, есть ли действительные проблемы
            <br /> и какой тип терапии вам поможет. Займет 15 минут, бесплатно
          </p>
        </div>
        <div className="survey__btn-wrap">
          <a href="#application" className="survey__btn btn btn--violet">
            Получить консультацию
          </a>
        </div>
        <div className="survey-video__wrap">
          <video
            src={DotBannerMp4}
            className="survey-video"
            width="100%"
            playsInline
            height="auto"
            muted
            loop
            autoPlay
            preload="auto"
          />
        </div>
      </div>
    </div>
  </section>
);

export default SurveyPsy;
