import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Modal } from 'antd';
import { InputOTP } from 'antd-input-otp';

import { Button, Typography } from '1_shared/ui';

import { LoginStatus } from '../../../1_shared/config/interfaces/LoginStatus';
import { IApplicationForm } from '../../../4_widgets/OrderForm/ui/interface/IApplicationForm';

import { IPropsEnterCode } from './interfaces/IPropsEnterCode';

import styles from './EnterCode.module.scss';

const EnterCode = ({
  open,
  onClose,
  control,
  name,
  foundUser,
  phone,
  nickName,
  registerUser,
  isLogin,
  setStatus,
}: IPropsEnterCode) => {
  const [timer, setTimer] = useState<number>(80);
  const [errorCode, setErrorCode] = useState<string | null>();
  useEffect(() => {
    const interval = setInterval(() => {
      if (timer > 0 && open) {
        setTimer(prev => prev - 1);
      }
    }, 1000);

    if (timer <= 0) {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [open, timer]);

  const onSubmit = async (value: string, onChange: (val: string) => void) => {
    try {
      if (isLogin) {
        await foundUser(phone.replace('_', '') || '', value);
      } else {
        await registerUser(phone.replace('_', '') || '', value);
      }
      onChange('');
      setErrorCode(null);
      onClose();
    } catch {
      setErrorCode('Введен не валидный код');
    }
  };

  const handleChange = async (
    onChange: (value: string) => void,
    value: string,
    fieldValue: string,
  ) => {
    if (!value) {
      onChange(fieldValue.slice(fieldValue.length - 2, fieldValue.length - 1));
    } else if ((fieldValue + value).length < 4) {
      onChange(fieldValue + value);
    } else {
      await onSubmit(fieldValue + value, onChange);
    }
  };

  return (
    <Modal centered open={open} onCancel={onClose} footer={null}>
      <div className={styles.root}>
        <Typography type="title">Введите код из СМС</Typography>
        <Controller
          control={control}
          name={name as keyof IApplicationForm}
          render={({ field }) => (
            <div className={styles.wrappInput}>
              <div className={styles.wrappCode}>
                <InputOTP
                  type="number"
                  inputType="numeric"
                  length={4}
                  onChange={val => {
                    handleChange(
                      field.onChange,
                      val[val.length - 1],
                      field.value || '',
                    );
                  }}
                />
              </div>
              {errorCode && (
                <Typography type="description">{errorCode}</Typography>
              )}
              <div className={styles.btns}>
                <div className={styles.repeatCode}>
                  <Button
                    type="secondary"
                    disabled={timer !== 0}
                    onClick={async () => {
                      setStatus(LoginStatus.None);
                      if (isLogin) {
                        await foundUser(
                          phone.replace('_', '') || '',
                          field?.value,
                        );
                      } else {
                        await registerUser(
                          phone.replace('_', '') || '',
                          nickName,
                          field?.value,
                        );
                      }
                      setStatus(LoginStatus.CodeSent);
                      setTimer(85);
                    }}
                  >
                    ОТПРАВИТЬ КОД ПОВТОРНО
                  </Button>

                  {timer > 0 && (
                    <Typography type="subtitle">{`Будет доступно черех ${timer}`}</Typography>
                  )}
                </div>
              </div>
            </div>
          )}
        />
      </div>
    </Modal>
  );
};

export default EnterCode;
