import { FC } from 'react';
import cn from 'classnames';

import { isRegionUz } from '../../../helpers/RegionHelpers/isRegionUz';

import IPageProps from './interfaces/IPageProps';

import styles from './Page.module.scss';

const Page: FC<IPageProps> = ({ children }) => (
  <div className={cn(styles.page, { [styles.pageUZ]: isRegionUz() })}>
    {children}
  </div>
);

export default Page;
