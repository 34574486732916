import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import useSWRMutation from 'swr/mutation';

import { ISpecialistShortData } from '1_shared/config/interfaces/ISpecialistShortData';

import { specApiInstance } from '../../../1_shared/api/apiInstance';
import { ISlot } from '../../../1_shared/config/interfaces/ISlot';
import { RoutePath } from '../../../1_shared/config/routes';
import { isRegionUz } from '../../../1_shared/helpers/isRegionUz';
import { useAuthContext } from '../../../app/module/lib/hooks/useAuthContext';
import { env } from '../../../env';
import { IApplicationForm } from '../ui/interface/IApplicationForm';

import { IPromocodeInput } from './api/interfaces/IPromocodeInput';
import { IPromoCodeOutput } from './api/interfaces/IPromoCodeOutput';
import { makeOrder } from './api/specService';
import { IPaymentHookOutput } from './interfaces/IPaymentHookOutput';

const usePayment = (updateNickname: any): IPaymentHookOutput => {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const [promoCode, setPromoCode] = useState<IPromoCodeOutput | undefined>();
  const [errorPromo, setErrorPromo] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onPromoCodeFind = async (promo: IPromocodeInput) => {
    try {
      setIsLoading(true);
      const req = await specApiInstance.get('ls/promocodes/v1/find', {
        params: promo,
      });
      setPromoCode(req.data);
      setIsLoading(false);
    } catch (e) {
      setErrorPromo((e as AxiosError<any>).response?.data?.message);
      setIsLoading(false);
    }
  };

  const { trigger: makeOrderTrigger } = useSWRMutation(
    'ss/order/v2/make-order',
    makeOrder,
  );

  const payment = async (
    cost: number,
    data: IApplicationForm,
    spec: ISpecialistShortData,
    slot: ISlot,
  ) => {
    await updateNickname(data?.name);
    const order = await makeOrderTrigger({
      slotId: data?.slotId,
      price: cost,
      discount: Number(promoCode?.discount),
      discountedPrice: cost - Number(promoCode?.discount),
      promoCode: data?.promoCode || null,
    });

    if (!isRegionUz()) {
      // @ts-ignore
      ym(97788822, 'reachGoal', 'pay_button');
    }

    if (order.discountedPrice > 0) {
      const receipt = {
        items: [
          {
            label: `Запись на сеанс к ${spec.secondName} ${spec.firstName} ${dayjs().format('DD-MM-YYYY, HH:mm:ss')}`, // наименование товара
            price: order.discountedPrice, // цена
            quantity: 1, // количество
            amount: order.discountedPrice, // сумма
            vat: 0,
            method: 4,
          },
        ],
        taxationSystem: 2,
        phone: `+${user?.phone}`, // телефон покупателя в любом формате, если нужно отправить сообщение со ссылкой на чек
        email: user?.email,
        customerInfo: user?.nickname, // тег-1227 Покупатель - наименование организации или фамилия, имя, отчество (при наличии), серия и номер паспорта покупателя (клиента)
        amounts: {
          electronic: order.discountedPrice, // Сумма оплаты электронными деньгами
          advancePayment: 0.0, // Сумма из предоплаты (зачетом аванса) (2 знака после точки)
          credit: 0.0, // Сумма постоплатой(в кредит) (2 знака после точки)
          provision: 0.0, // Сумма оплаты встречным предоставлением (сертификаты, др. мат.ценности) (2 знака после точки)
        },
      };

      // @ts-ignore
      const paymentWidget = new cp.CloudPayments({
        googlePaySupport: true,
        yandexPaySupport: true,
        tinkoffInstallmentSupport: true,
      });

      paymentWidget.pay(
        'charge',
        {
          publicId: String(env.REACT_APP_CLOUD_PAY),
          description: `Запись на сеанс к ${spec.secondName} ${spec.firstName} ${dayjs(slot.start).format('DD-MM-YYYY, HH:mm:ss')}`,
          invoiceId: order.invoiceId,
          amount: order.discountedPrice,
          email: user?.email,
          accountId: user?.id,
          data: {
            cloudPayments: {
              customerReceipt: receipt, // онлайн-чек
            },
          },
          payer: {
            phone: `+${user?.phone}`,
          },
          isBso: true,
          currency: 'RUB',
          skin: 'mini',
        },
        {
          onSuccess() {
            // success
            if (!isRegionUz()) {
              // @ts-ignore
              ym(338534252, 'init', {
                clickmap: true,
              });
            }
            navigate(RoutePath.REACT_ORDER_OK, {
              state: {
                email: data?.email,
                phone: data?.login,
                name: data?.name,
                cost: order?.discountedPrice,
                spec: {
                  firstName: spec?.firstName,
                  secondName: spec?.secondName,
                },
                slot,
              },
            });
            // действие при успешной оплате
          },
          onFail() {
            // fail
            navigate(RoutePath.SPECIALISTS);
            // действие при неуспешной оплате
          },
        },
      );
    } else {
      navigate(RoutePath.REACT_ORDER_OK, {
        state: {
          email: data?.email,
          phone: data?.login,
          name: data?.name,
          cost: order?.discountedPrice,
          spec: {
            firstName: spec?.firstName,
            secondName: spec?.secondName,
          },
          slot,
        },
      });
    }
  };

  return {
    payment,
    onPromoCodeFind,
    promoCode,
    errorPromo,
    isPromoLoading: isLoading,
    setPromoCode,
  };
};

export default usePayment;
