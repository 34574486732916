import DotBannerMp4 from '1_shared/assets/video/dot_banner.mp4';

import 'app/styles/global/global.scss';
import './try-group.scss';

const TryGroup = () => (
  <section className="try-group">
    <div className="container try-group__container">
      <div className="try-group__inner">
        <div className="try-group__content">
          <h2 className="try-group__title section-title">
            Попробуйте групповой формат
          </h2>
          <p className="try-group__subtitle">
            В кругу единомышленников дойти до цели терапии легче и эффективнее
          </p>
          {/* <p className="try-group__subtitle">Поможем понять, какой вид групповой терапии эффективнее решает ваш запрос</p> */}
          {/* <ul className="try-group__subtitle">
          <li>Чтобы приблизиться к цели терапии быстрее и эффективнее -- в поддерживающем кругу единомышленников</li>
          <li>В кругу единомышленников дойти до цели терапии легче и эффективнее</li>
        </ul> */}
          <a href="/" className="try-group__link btn">
            Выбрать
          </a>
        </div>
        <div className="survey-video__wrap">
          <video
            src={DotBannerMp4}
            className="survey-video"
            width="100%"
            height="auto"
            muted
            loop
            playsInline
            autoPlay
          />
        </div>
      </div>
    </div>
  </section>
);

export default TryGroup;
