import { useCallback, useMemo, useState } from 'react';
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';

import { useAuthContext } from '../../../app/module/lib/hooks/useAuthContext';
import { env } from '../../../env';
import { ConferenceEvents } from '../../constants/conference';

const wsURL = `${env.REACT_APP_SOCKET_URL}/ns/sessions`;

const useSocketConnection = () => {
  const { user } = useAuthContext();
  const socket = useMemo(() => new SockJS(wsURL), []);
  const client = useMemo(() => Stomp.over(socket), []);
  const [uuid, setUUID] = useState<string | null>(null);

  const subscribeDestination = (uuid: string, callback: any) => {
    client?.subscribe(`/event/${uuid}`, (res: any) => {
      callback(JSON.parse(res.body));
    });
  };

  const onConnect = (uuid: string, callback: any) => () => {
    subscribeDestination(uuid, callback);
  };

  const connectWS = (uuid: string, callback: any) => {
    setUUID(uuid);
    client.connect({}, onConnect(uuid, callback));
    client.debug = () => {};
  };

  const sendMessage = useCallback(
    (event: ConferenceEvents) => {
      if (user)
        client?.send(
          `/event/${uuid}`,
          {},
          JSON.stringify({
            event,
            user:
              event === ConferenceEvents.CONNECTING ||
              event === ConferenceEvents.CONNECTED ||
              event === ConferenceEvents.ASK_STATUS
                ? user
                : { id: user.id },
          }),
        );
    },
    [client, uuid, user],
  );

  const sendStatus = useCallback(
    (event: ConferenceEvents, status: any) => {
      client?.send(
        `/event/${uuid}`,
        {},
        JSON.stringify({
          event,
          user,
          status,
        }),
      );
    },
    [client, uuid, user],
  );

  const unsub = useCallback(() => {
    client?.unsubscribe(`/event/${uuid}`);
  }, [client, uuid]);

  return {
    client,
    connectWS,
    sendMessage,
    unsub,
    sendStatus,
  };
};
export default useSocketConnection;
