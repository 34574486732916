import EduTrack1Img from '1_shared/assets/images/edu-track/edu-track-1@1x.png';
import EduTrack2Img from '1_shared/assets/images/edu-track/edu-track-2@1x.png';
import EduTrack3Img from '1_shared/assets/images/edu-track/edu-track-3@1x.png';
import EduTrack4Img from '1_shared/assets/images/edu-track/edu-track-4@1x.png';

import './data-tabs.scss';
import 'app/styles/global/global.scss';
import './edu-track.scss';

const EduTrack = () => (
<section className="edu-track">
  <div className="container">
    <div className="edu-track__inner">
      <span className="edu-track__suptitle suptitle">мыслим комплексно</span>
      <h2 className="edu-track__title section-title">Образовательный трек для</h2>

      <div className="edu-track__tabs" data-tabs="parent">
        <div className="edu-track__slider swiper" id="eduTrackSlider">
          <div className="edu-track__tabs-controls swiper-wrapper" data-tabs="controls">
            <a href="/" className="edu-track__tabs-link swiper-slide" data-tabs="control">супервизии</a>
            <a href="/" className="edu-track__tabs-link swiper-slide" data-tabs="control">групповой терапии</a>
            <a href="/" className="edu-track__tabs-link swiper-slide" data-tabs="control">студентов</a>
            <a href="/" className="edu-track__tabs-link swiper-slide" data-tabs="control">опытных</a>
          </div>
        </div>

        <div className="edu-track__tabs-content" data-tabs="content">
          <span>Сегодня</span>
          <div className="edu-track__tabs-item" data-tabs="element">
            <div className="edu-track__item">
              <img className="edu-track__image" src={EduTrack1Img} alt="Нейропсихология и когнитивные расстройства" />
              <span className="edu-track__type">курс</span>
              <p>Нейропсихология и когнитивные расстройства</p>
            </div>
            <div className="edu-track__item">
              <img className="edu-track__image" src={EduTrack2Img} alt="Эффективные методы групповой терапии" />
              <span className="edu-track__type">курс</span>
              <p>Эффективные методы групповой терапии</p>
            </div>
            <div className="edu-track__item">
              <img className="edu-track__image" src={EduTrack3Img} alt="Cовременные подходы в кризисной психологии" />
              <span className="edu-track__type">курс</span>
              <p>Cовременные подходы в кризисной психологии</p>
            </div>
            <div className="edu-track__item">
              <img className="edu-track__image" src={EduTrack4Img} alt="Применение арт-терапии в психологической практике" />
              <span className="edu-track__type">курс</span>
              <p>Применение арт-терапии в психологической практике</p>
            </div>
            <div className="edu-track__btns">
              <a href="/" className="edu-track__btn btn btn--violet">Подобрать курс</a>
              <a href="/" className="edu-track__btn btn btn--light">Подобрать индивидуально</a>
            </div>
          </div>
          <div className="edu-track__tabs-item" data-tabs="element">
            <div className="edu-track__item">
              <img className="edu-track__image" src={EduTrack2Img} alt="Эффективные методы групповой терапии" />
              <span className="edu-track__type">курс</span>
              <p>Эффективные методы групповой терапии</p>
            </div>
            <div className="edu-track__item">
              <img className="edu-track__image" src={EduTrack1Img} alt="Нейропсихология и когнитивные расстройства" />
              <span className="edu-track__type">курс</span>
              <p>Нейропсихология и когнитивные расстройства</p>
            </div>
            <div className="edu-track__item">
              <img className="edu-track__image" src={EduTrack3Img} alt="Cовременные подходы в кризисной психологии" />
              <span className="edu-track__type">курс</span>
              <p>Cовременные подходы в кризисной психологии</p>
            </div>
            <div className="edu-track__item">
              <img className="edu-track__image" src={EduTrack4Img} alt="Применение арт-терапии в психологической практике" />
              <span className="edu-track__type">курс</span>
              <p>Применение арт-терапии в психологической практике</p>
            </div>
            <div className="edu-track__btns">
              <a href="/" className="edu-track__btn btn btn--violet">Подобрать курс</a>
              <a href="/" className="edu-track__btn btn btn--light">Подобрать индивидуально</a>
            </div>
          </div>
          <div className="edu-track__tabs-item" data-tabs="element">
            <div className="edu-track__item">
              <img className="edu-track__image" src={EduTrack3Img} alt="Cовременные подходы в кризисной психологии" />
              <span className="edu-track__type">курс</span>
              <p>Cовременные подходы в кризисной психологии</p>
            </div>
            <div className="edu-track__item">
              <img className="edu-track__image" src={EduTrack1Img} alt="Нейропсихология и когнитивные расстройства" />
              <span className="edu-track__type">курс</span>
              <p>Нейропсихология и когнитивные расстройства</p>
            </div>
            <div className="edu-track__item">
              <img className="edu-track__image" src={EduTrack2Img} alt="Эффективные методы групповой терапии" />
              <span className="edu-track__type">курс</span>
              <p>Эффективные методы групповой терапии</p>
            </div>
            <div className="edu-track__item">
              <img className="edu-track__image" src={EduTrack4Img} alt="Применение арт-терапии в психологической практике" />
              <span className="edu-track__type">курс</span>
              <p>Применение арт-терапии в психологической практике</p>
            </div>
            <div className="edu-track__btns">
              <a href="/" className="edu-track__btn btn btn--violet">Подобрать курс</a>
              <a href="/" className="edu-track__btn btn btn--light">Подобрать индивидуально</a>
            </div>
          </div>
          <div className="edu-track__tabs-item" data-tabs="element">
            <div className="edu-track__item">
              <img className="edu-track__image" src={EduTrack4Img} alt="Применение арт-терапии в психологической практике" />
              <span className="edu-track__type">курс</span>
              <p>Применение арт-терапии в психологической практике</p>
            </div>
            <div className="edu-track__item">
              <img className="edu-track__image" src={EduTrack1Img} alt="Нейропсихология и когнитивные расстройства" />
              <span className="edu-track__type">курс</span>
              <p>Нейропсихология и когнитивные расстройства</p>
            </div>
            <div className="edu-track__item">
              <img className="edu-track__image" src={EduTrack2Img} alt="Эффективные методы групповой терапии" />
              <span className="edu-track__type">курс</span>
              <p>Эффективные методы групповой терапии</p>
            </div>
            <div className="edu-track__item">
              <img className="edu-track__image" src={EduTrack3Img} alt="Cовременные подходы в кризисной психологии" />
              <span className="edu-track__type">курс</span>
              <p>Cовременные подходы в кризисной психологии</p>
            </div>
            <div className="edu-track__btns">
              <a href="/" className="edu-track__btn btn btn--violet">Подобрать курс</a>
              <a href="/" className="edu-track__btn btn btn--light">Подобрать индивидуально</a>
            </div>
          </div>
          <span>4 месяца</span>
        </div>
      </div>
    </div>
  </div>
</section>
);

export default EduTrack;