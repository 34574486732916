import { ISlicedSpecialistData } from '../../../../1_shared/config/interfaces/ISpecialistData';
import { ISpecialistListFiltersQuery } from '../../../../1_shared/config/interfaces/ISpecialistListFilters';
import { isRegionUz } from '../../../../1_shared/helpers/RegionHelpers/isRegionUz';
import {
  Button,
  MeetingsSingle2,
  SubscribeBlock,
} from '../../../../1_shared/ui';
import { HelpChooseUz, SpecialistCard } from '../../../../4_widgets';
import HelpChoose from '../../../../4_widgets/HelpChoose/ui/HelpChoose';
import { SpecialistFilters } from '../../../../4_widgets/SpecialistFilters';

import styles from '../SpecialistsPage.module.scss';

const SpecialistListByRegion = (props: {
  specList?: ISlicedSpecialistData;
  handleFiltersChange: (filters: ISpecialistListFiltersQuery) => void;
  totalShowedSpecs: number;
  total: number;
  viewMore: () => void;
}) => {
  const { handleFiltersChange, specList, totalShowedSpecs, total, viewMore } =
    props;

  if (isRegionUz()) {
    return (
      <div>
        {specList?.first?.map(spec => (
          <SpecialistCard spec={spec} key={spec?.id} showDescription />
        ))}
        <SubscribeBlock />
        {!!specList?.second?.length &&
          specList?.second?.map((spec, index) => (
            <SpecialistCard
              spec={spec}
              key={spec?.id}
              slicedBlock
              className={index === 0 ? styles.block : undefined}
            />
          ))}
        <MeetingsSingle2 />
        {!!specList?.third?.length &&
          specList?.third?.map((spec, index) => (
            <SpecialistCard
              spec={spec}
              key={spec?.id}
              slicedBlock
              className={index === 0 ? styles.block : undefined}
            />
          ))}
        <HelpChooseUz
          onChange={handleFiltersChange}
          specs={{ specList, total, totalShowedSpecs, viewMore }}
        />
      </div>
    );
  }

  return (
    <div>
      <SpecialistFilters onChange={handleFiltersChange} />
      {[
        ...(specList?.first || []),
        ...(specList?.second || []),
        ...(specList?.third || []),
        ...(specList?.fourth || []),
      ]?.map(spec => <SpecialistCard spec={spec} key={spec?.id} />)}
      {totalShowedSpecs !== total && (
        <div className={styles.viewMore}>
          <Button onClick={viewMore}>ПОКАЗАТЬ БОЛЬШЕ</Button>
        </div>
      )}
      <HelpChoose onChange={handleFiltersChange} />
    </div>
  );
};

export default SpecialistListByRegion;
