import { Mousewheel } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { VideoComponent } from '1_shared/ui';

import { videoMockb2c } from '../../../1_shared/config/videoMock';

import 'swiper/scss';
import 'app/styles/global/global.scss';
import './video.scss';

const VideoStory = () => (
  <section className="video">
    <div className="video__container container">
      <h2 className="video__title section-title">Актуальные истории</h2>
    </div>
    <div className="video__inner">
      <Swiper
        spaceBetween={20}
        slidesPerView="auto"
        className="video__slider"
        wrapperClass="video__items"
        id="videoSlider"
        mousewheel
        modules={[Mousewheel]}
        setWrapperSize
      >
        {videoMockb2c.map(item => (
          <SwiperSlide key={item.title} className="video__item swiper-slide">
            <VideoComponent item={item} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  </section>
);

export default VideoStory;
