import { isRegionUz } from './isRegionUz';

export const getCurrencyByRegion = () => {
  if (isRegionUz()) {
    return 'т.uzs';
  }

  // Default region Russia
  return '₽';
};
