import { RoutePath } from '../../../config/routes';

import { IConfFooterLink } from './interfaces/IConfFooterLink';

export const footerLinks: IConfFooterLink[] = [
  {
    label: 'О платформе',
    url: '/#about',
    isAppLink: false,
  },
  {
    label: 'Блог',
    url: 'https://old.dotherapy.ru/blog',
    isAppLink: false,
  },
  {
    label: 'Для специалистов',
    url: RoutePath.REACT_BETTER_PSYCHOLOGICAL,
    isAppLink: true,
  },
  {
    label: 'Частые вопросы',
    url: '/#faq',
    isAppLink: false,
  },
  {
    label: 'Вход на платформу',
    url: RoutePath.LOGIN,
    isAppLink: true,
  },
];

export const footerLinksUZ = [
  {
    label: 'О платформе',
    url: '/#about',
    isAppLink: false,
  },
  {
    label: 'Частые вопросы',
    url: '/#faq',
    isAppLink: false,
  },
  {
    label: 'Вход на платформу',
    url: RoutePath.LOGIN,
    isAppLink: true,
  },
];
