import 'app/styles/global/global.scss';
import './meetings.scss';

const MeetingsGroup = () => (
  <section className="meetings meetings--group" id="meetingsGroup">
    <div className="container meetings__container">
      <div className="meetings__heading ">
        <h2 className="meetings__title section-title">
          Как проходят занятия в&nbsp;группах
        </h2>
        <p className="meetings__subtitle">
          Онлайн-встречи с экспертом психологии
          <br /> в группах до 10 человек. Подойдут как новичку
          <br /> в терапии, так и опытному клиенту и психологу
        </p>
      </div>
      <div className="meetings__wrapper">
        <div className="meetings__inner">
          <div className="meetings__item">
            <h3>
              <b>Структурированность и&nbsp;план занятий</b>
              <div className="meetings__circle" />
            </h3>
            <p>
              Групповые занятия обычно имеют четкую структуру и план, который
              включает в себя разные темы и активности. Занятия могут быть
              организованы вокруг конкретной проблематики, спектра запросов,
              методов терапии или развития важных профессиональных навыков
              психолога
            </p>
          </div>
          <div className="meetings__item">
            <h3>
              <b>
                Активное участие
                <br /> и&nbsp;взаимодействие
              </b>
              <div className="meetings__circle" />
            </h3>
            <p>
              Участники активно включаются в дискуссии, выполнение упражнений и
              прочую совместную деятельность. Групповая динамика и
              взаимодействие между участниками играют большую роль в
              эффективности обучения и процессе самопознания
            </p>
          </div>
          <div className="meetings__item">
            <h3>
              <b>
                Руководство
                <br /> Психолога
                <br /> или&nbsp;Терапевта
              </b>
              <div className="meetings__circle" />
            </h3>
            <p>
              Занятия ведутся квалифицированным специалистом, который
              обеспечивает безопасную и поддерживающую среду, превращая группу в
              терапевтический инструмент. Ведущий осуществляет отбор участников
              и контролирует соблюдение правил, направляет дискуссии, предлагает
              упражнения и помогает участникам интегрировать полученный опыт
            </p>
          </div>
          <div className="meetings__item">
            <h3>
              <b>
                Обмен Опытом
                <br /> и&nbsp;Поддержка
              </b>
              <div className="meetings__circle" />
            </h3>
            <p>
              Участники делятся своими переживаниями, историями и проблемами,
              что способствует развитию взаимопонимания
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default MeetingsGroup;
