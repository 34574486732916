import { Content, Footer, Page, Survey, ToRead } from '1_shared/ui';
import { ProfileUser } from '2_entities';
import { Header, PcClientTabs } from '4_widgets';

import { isRegionUz } from '../../../1_shared/helpers/RegionHelpers/isRegionUz';

const PcClient = () => (
  <Page>
    <Header />
    <Content>
      <ProfileUser />
    </Content>
    <PcClientTabs />
    {!isRegionUz() && <Survey />}
    <ToRead />
    <Footer />
  </Page>
);

export default PcClient;
