import { Spin } from 'antd';

import ConfStatusesMap, {
  ConferenceStatus,
} from '../../../1_shared/constants/conference';
import { configMedia } from '../../../4_widgets/SpecialistCard/ui/config/configMedia';

import styles from './SessionState.module.scss';

const SessionState = ({
  status,
  remoteUser,
  remoteMedia,
}: {
  status: ConferenceStatus;
  remoteUser: any;
  remoteMedia: any;
}) => (
    <div className={styles.root}>
      {!remoteUser && (
        <>
          {status !== 'not-alone' && status !== 'error' && <Spin />}
          <div className="flicker">{ConfStatusesMap[status]}</div>
        </>
      )}
      {(status === 'connected' || status === 'not-alone') &&
        remoteUser &&
        remoteMedia.video === 'removed' && (
          <div className={styles.remoteUser}>
            <div
              className={styles.remoteUserAvatar}
              style={{
                backgroundImage: `url("${
                  remoteUser?.avatar?.file_url ||
                  configMedia.mediaContentResponse.previewLink
                }")`,
              }}
            />
            {remoteUser?.nickname && remoteUser?.be_anonymous
              ? 'Аноним'
              : `${remoteUser.nickname || ''}`}
            {remoteUser?.lastName && remoteUser?.be_anonymous
              ? 'Аноним'
              : `${remoteUser?.last_name || ''} ${remoteUser?.first_name || ''}`}
            {status !== 'not-alone' && (
              <div className="flicker">подключается</div>
            )}
          </div>
        )}
    </div>
  );
export default SessionState;
