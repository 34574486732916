import {
  About,
  Cases,
  EduTrack,
  Faq,
  Feedback,
  Footer,
  HeroPsy,
  MeetingsHowClassRun,
  ProgramsPsy,
  PsychologistsNearLearningPath,
  Specialists,
  StatisticsPsy,
  SurveyPsy,
} from '1_shared/ui';
import Page from '1_shared/ui/Page/ui/Page';
import { Header } from '4_widgets';

const ReactBetterPsy = () => (
  <Page>
    <Header />
    <HeroPsy />
    <StatisticsPsy />
    <EduTrack />
    <ProgramsPsy />
    <SurveyPsy />
    <Cases />
    <MeetingsHowClassRun />
    <Specialists />
    <About />
    <PsychologistsNearLearningPath />
    <Feedback />
    <Faq />
    <Footer />
  </Page>
);

export default ReactBetterPsy;
