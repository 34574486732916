import React from 'react';

import {
  About,
  DirectionsGroup,
  DirectionsSingle,
  Experts,
  Faq,
  Feedback,
  Footer,
  HeroMain,
  MeetingsGroup,
  MeetingsSingle,
  Problem,
  Programs,
  Progs4Psychologists,
  PsychologistsNear,
  Specialists,
  Statistics,
  Survey,
} from '1_shared/ui'; // Забираем нужную нам функцию из пакета, функции именуются - компонентами
import Page from '1_shared/ui/Page/ui/Page';
import { Header, Video } from '4_widgets';

import { isRegionUz } from '../../../1_shared/helpers/RegionHelpers/isRegionUz';

const MainPage: React.FC = () => (
    <Page>
      <Header />
      <HeroMain />
      <Statistics />
      <Problem />
      {!isRegionUz() && <Video />}
      {!isRegionUz() && <Survey />}
      <DirectionsSingle />
      <Specialists />
      <MeetingsSingle />
      <DirectionsGroup />
      <Programs />
      <MeetingsGroup />
      <PsychologistsNear />
      <About />
      <Experts />
      <Feedback />
      <Progs4Psychologists />
      <Faq />
      <Footer />
    </Page>
  );

export default MainPage;
