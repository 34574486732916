import PaymentDefaultImg from '1_shared/assets/images/payment-details@2x.png';
import SpecialistImg from '1_shared/assets/images/specialists/specialist-icon-1@2x.jpg';
import SpriteSvg from '1_shared/assets/pictures/stack/stack.svg';

import { getCurrencyByRegion } from '../../../helpers/RegionHelpers/getCurrencyByRegion';

import './custom-select.scss';
import 'app/styles/global/global.scss';
import './order.scss';
import './form.scss';
import './form-checkbox.scss';

const Order = () => (
  <section className="order">
    <div className="order__container container">
      <h1 className="order__title">Запись на прием</h1>
      <div className="order__form form">
        <form action="#" method="post">
          <div className="form__row form__row--header">
            <span className="form__label">Специалист</span>
            <div className="form__field form__field--specialist">
              <img src={SpecialistImg} alt="Петрушенко Евгения" />
              <p>Петрушенко Евгения</p>
            </div>
          </div>

          <div className="form__row form__row--with-btn">
            {/* <label htmlFor="date" className="form__label">Дата и время</label> */}
            <div className="form__row-inner">
              <div className="form__field form__field--row">
                <div className="form__field-wrap form__field-wrap--date">
                  <div
                    className="custom-select"
                    data-select="data-select"
                    data-validate-type="select"
                    data-name="date"
                    data-id="date"
                  >
                    <span className="custom-select__placeholder">Дата</span>
                    <button
                      className="custom-select__button"
                      type="button"
                      aria-label="Выберите одну из опций"
                    >
                      <span className="custom-select__text" />
                      <span className="custom-select__icon" />
                    </button>
                    <ul className="custom-select__list">
                      <li
                        className="custom-select__item"
                        data-select-value="id-1"
                        aria-selected="true"
                        role="option"
                      >
                        13 декабря
                      </li>
                      <li
                        className="custom-select__item"
                        data-select-value="id-2"
                        aria-selected="false"
                        role="option"
                      >
                        14 декабря
                      </li>
                      <li
                        className="custom-select__item"
                        data-select-value="id-3"
                        aria-selected="false"
                        role="option"
                      >
                        15 декабря
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="form__field-wrap form__field-wrap--time">
                  <div
                    className="custom-select"
                    data-select="data-select"
                    data-validate-type="select"
                    data-name="time"
                    data-id="time"
                  >
                    <span className="custom-select__placeholder">Время</span>
                    <button
                      className="custom-select__button"
                      type="button"
                      aria-label="Выберите одну из опций"
                    >
                      <span className="custom-select__text" />
                      <span className="custom-select__icon" />
                    </button>
                    <ul className="custom-select__list">
                      <li
                        className="custom-select__item"
                        data-select-value="id-1"
                        aria-selected="true"
                        role="option"
                      >
                        14:00
                      </li>
                      <li
                        className="custom-select__item"
                        data-select-value="id-2"
                        aria-selected="false"
                        role="option"
                      >
                        16:00
                      </li>
                      <li
                        className="custom-select__item"
                        data-select-value="id-3"
                        aria-selected="false"
                        role="option"
                      >
                        18:00
                      </li>
                    </ul>
                  </div>
                </div>
                <button className="form__btn-resolve" type="button">
                  Выбрать ближайшее доступное время
                </button>
              </div>
              <div className="form__prompt">
                <p>Длительность сеанса — 2 часа</p>
              </div>
            </div>
          </div>

          <div className="form__row form__row--with-btn">
            {/* <label htmlFor="name" className="form__label">Имя или псевдоним</label> */}
            <div className="form__row-inner">
              <div className="form__field">
                <div className="form__field-wrap">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Иван Константинов"
                  />
                </div>
                <button className="form__btn-resolve" type="button">
                  Сгенерировать псеводним
                </button>
              </div>
              <div className="form__prompt">
                <p>
                  Можете указать псевдоним, если не хотите раскрывать имя. Ваши
                  данные строго конфиденциальны
                </p>
              </div>
            </div>
          </div>

          <div className="form__row">
            {/* <label htmlFor="email" className="form__label">Email</label> */}
            <div className="form__row-inner">
              <div className="form__field">
                <div className="form__field-wrap">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="hello@kexit.it"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="form__row">
            {/* <label htmlFor="phone" className="form__label">Телефон</label> */}
            <div className="form__row-inner">
              <div className="form__field">
                <div className="form__field-wrap">
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    placeholder="+7 999 66-6699"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="form__row">
            {/* <label htmlFor="promocode" className="form__label">Промокод</label> */}
            <div className="form__row-inner">
              <div className="form__field">
                <div className="form__field-wrap form__field-wrap--promo">
                  <input
                    type="text"
                    id="promocode"
                    name="promocode"
                    placeholder=""
                  />
                  <button className="form__promo-btn" type="button">
                    применить
                  </button>
                </div>
              </div>
              <div className="form__prompt">
                <p>
                  Код из подарочного сертификата <br /> тоже сюда
                </p>
              </div>
            </div>
          </div>

          <div className="form__row form__row--payment">
            <span className="form__label">Способ оплаты</span>
            <div className="form__radio-inner">
              <div className="form__radio-box">
                <div className="form__field">
                  <div className="form__radio">
                    <input
                      type="radio"
                      name="payment"
                      id="payment-card"
                      value="payment-card"
                    />
                    {/* <label htmlFor="payment-card">Картой</label> */}
                    <svg viewBox="0 0 87 12" width="87" height="12">
                      <use href={`${SpriteSvg}#payment-card`} />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="form__radio-box">
                <div className="form__field">
                  <div className="form__radio">
                    <input
                      type="radio"
                      name="payment"
                      id="tinkoff-pay"
                      value="tinkoff-pay"
                    />
                    {/* <label htmlFor="tinkoff-pay">Tinkoff Pay</label> */}
                    <svg viewBox="0 0 35 16" width="35" height="16">
                      <use href={`${SpriteSvg}#pay`} />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="form__radio-box">
                <div className="form__field">
                  <div className="form__radio">
                    <input
                      type="radio"
                      name="payment"
                      id="payment-shares"
                      value="payment-shares"
                      checked
                    />
                    {/* <label htmlFor="payment-shares">Долями</label> */}
                    <svg viewBox="0 0 77 12" width="77" height="12">
                      <use href={`${SpriteSvg}#payment-shares`} />
                    </svg>
                  </div>
                  <div className="form__payment-shares">
                    <span>4 платежа по 1200&nbsp;{getCurrencyByRegion()}</span>
                    <img src={PaymentDefaultImg} alt="" />
                  </div>
                </div>
                <div className="form__prompt">
                  <p>
                    Оплатите 25% от стоимости покупки, а оставшиеся 3 части
                    спишутся автоматически с шагом в две недели. Без процентов и
                    комиссий, как обычная оплата картой
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="form__row form__row--consent">
            <span className="form__label">согласия</span>
            <div className="form__checkbox-inner">
              <div className="form__checkbox-box">
                <div className="form-checkbox">
                  <input type="checkbox" name="agreement" id="agreement" />
                  {/* <label htmlFor="agreement">Принять пользовательское соглашение и договор оферы</label> */}
                </div>
              </div>
              <div className="form__checkbox-box">
                <div className="form-checkbox">
                  <input type="checkbox" name="consent" id="consent" />
                  {/* <label htmlFor="consent">Согласие на обработку данных</label> */}
                </div>
              </div>
            </div>
          </div>

          <div className="form__row form__row--footer">
            <span className="form__label">Итого</span>
            <div className="form__row-inner">
              <span className="form__total">4 965 {getCurrencyByRegion()}</span>
              <button className="form__submit btn btn--violet" type="submit">
                Оплатить
              </button>
              <div className="form__prompt">
                <p>
                  После оплаты мы пришлем <br /> вам доступ на платформу
                </p>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
);

export default Order;
