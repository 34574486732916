import DoTherapyLogo from '1_shared/assets/images/doTherapyLogo.png';
import PsyLogo from '1_shared/assets/pictures/PsyMobileUZ.svg';
import { IMediaContentById } from '1_shared/config/interfaces/IMediaContentById';
import { MediaType } from '1_shared/config/interfaces/MediaType';

import { isRegionUz } from '../../../../1_shared/helpers/RegionHelpers/isRegionUz';

export const configMedia: IMediaContentById = {
  id: '1',
  isDefault: true,
  mediaContentResponse: {
    isDefault: true,
    mediaType: MediaType.Image,
    mainMediaLink: !isRegionUz() ? DoTherapyLogo : PsyLogo,
    previewLink: !isRegionUz() ? DoTherapyLogo : PsyLogo,
  },
};
