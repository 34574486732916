import 'app/styles/global/global.scss';
import './hero.scss';

const HeroPsy = () => (
<section className="hero hero--psy">
  <div className="hero__bubbles" />
  <div className="hero__content hero__content--psy">
    <h2 className="hero__title hero__title--psy section-title-big">Станьте <span>лучше</span> как психолог</h2>
    <p>Практикумы и курсы для развития навыков психотерапии и консультирования
      до нового уровня. Начните монетизировать свои знания</p>
    <div className="hero__btn-wrap">
      <a href="anketa.html" className="hero__btn hero__btn--psy btn btn--violet">Подобрать курс</a>
      <a href="emergency.html" className="hero__btn btn btn--light">Какой мой уровень?</a>
    </div>
  </div>
</section>
);

export default HeroPsy;