import { Link } from 'react-router-dom';

import DotBannerMp4 from '1_shared/assets/video/dot_banner.mp4';

import { RoutePath } from '../../../config/routes';

import 'app/styles/global/global.scss';
import './survey.scss';

const Survey = () => (
  <section className="survey" id="survey">
    <div className="container survey__container">
      <div className="survey__inner">
        <div className="survey__heading">
          <h2 className="survey__title section-title">Нужна ли мне помощь? </h2>
          <p className="survey__subtitle">
            Проверьте в нашем опросе: отметьте свое самочувствие и актуальные
            проблемы, а мы всегда предложим подходящих специалистов
          </p>
        </div>
        <div className="survey__btn-wrap">
          <Link
            to={RoutePath.REACT_ANKETA}
            state={{ anchor: '#survey' }}
            className="survey__btn btn btn--violet"
          >
            Пройти опрос
          </Link>
        </div>
        <div className="survey-video__wrap">
          <video
            src={DotBannerMp4}
            className="survey-video"
            width="100%"
            height="auto"
            muted
            playsInline
            loop
            autoPlay
          />
        </div>
      </div>
    </div>
  </section>
);

export default Survey;
