import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import Cases1Img from '1_shared/assets/images/specialists/specialist-1@2x.jpg';

import 'swiper/scss';
import 'app/styles/global/global.scss';
import './cases.scss';

import 'swiper/scss/navigation';

const Cases = () => (
<section className="cases">
  <div className="container">
    <div className="cases__inner">
      <h2 className="cases__title section-title">Наши<br /> кейсы</h2>
      <Swiper
        slidesPerView= {1}
        loop
        navigation
        modules={[Navigation]}
        className="cases__slider swiper" 
        id="casesSlider"
      >
        <div className="swiper-wrapper">
          <SwiperSlide className="cases__item swiper-slide">
            <div className="cases__item-inner">
              <div className="cases__video-box">
                <div className="cases__video-wrap">
                  <img className="cases__video-image" src={Cases1Img} alt="Петрушенко Евгения" />
                  <button className="cases__video-btn button-play" type="button" aria-label="Play" />
                </div>
                <span className="cases__video-time cases__video-time--desktop">Видео 02:47</span>
              </div>

              <div className="cases__content-title-wrap">
                <h2 className="cases__content-title section-title">Петрушенко Евгения</h2>
                <span className="cases__video-time cases__video-time--mobile">Видео 02:47</span>
              </div>

              <div className="cases__content">
                <div className="cases__path-wrap">
                  <span>путь</span>
                  <div className="cases__path-inner">
                    <div className="cases__path-step cases__path-step--one">
                      <div className="cases__path-circle cases__path-circle--grey" />
                      <p>Коуч ICF, доход — 50.000 ₽ в месяц. Чувство неуверенности и ощущение тупика. Желание углубить компетенции и расширить знания.</p>
                    </div>
                    <div className="cases__path-step">
                      <div className="cases__path-circle cases__path-circle--blue" />
                      <p>Коуч ICF, доход — 150.000 ₽ в месяц. Уверенность в себе. Желание углубить компетенции и расширить знания.</p>
                    </div>
                  </div>
                </div>
                <div className="cases__trainings-wrap">
                  <span>Что проходила</span>
                  <ul className="cases__trainings">
                    <li>Иммерсивные учебные сессии по арт-терапии</li>
                    <li>Сессии с психологом онлайн</li>
                    <li>Исцеление раненого внутреннего ребенка</li>
                    <li>Стоп негатив! Группы дерефлексии</li>
                  </ul>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="cases__item swiper-slide">
            <div className="cases__item-inner">
              <div className="cases__video-box">
                <div className="cases__video-wrap">
                  <img className="cases__video-image" src={Cases1Img} alt="Петрушенко Евгения" />
                  <button className="cases__video-btn button-play" type="button" aria-label="Play" />
                </div>
                <span className="cases__video-time cases__video-time--desktop">Видео 02:47</span>
              </div>

              <div className="cases__content-title-wrap">
                <h2 className="cases__content-title section-title">Петрушенко Евгения</h2>
                <span className="cases__video-time cases__video-time--mobile">Видео 02:47</span>
              </div>

              <div className="cases__content">
                <div className="cases__path-wrap">
                  <span>путь</span>
                  <div className="cases__path-inner">
                    <div className="cases__path-step cases__path-step--one">
                      <div className="cases__path-circle cases__path-circle--grey" />
                      <p>Коуч ICF, доход — 50.000 ₽ в месяц. Чувство неуверенности и ощущение тупика. Желание углубить компетенции и расширить знания.</p>
                    </div>
                    <div className="cases__path-step">
                      <div className="cases__path-circle cases__path-circle--blue" />
                      <p>Коуч ICF, доход — 150.000 ₽ в месяц. Уверенность в себе. Желание углубить компетенции и расширить знания.</p>
                    </div>
                  </div>
                </div>
                <div className="cases__trainings-wrap">
                  <span>Что проходила</span>
                  <ul className="cases__trainings">
                    <li>Иммерсивные учебные сессии по арт-терапии</li>
                    <li>Сессии с психологом онлайн</li>
                    <li>Исцеление раненого внутреннего ребенка</li>
                    <li>Стоп негатив! Группы дерефлексии</li>
                  </ul>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="cases__item swiper-slide">
            <div className="cases__item-inner">
              <div className="cases__video-box">
                <div className="cases__video-wrap">
                  <img className="cases__video-image" src={Cases1Img} alt="Петрушенко Евгения" />
                  <button className="cases__video-btn button-play" type="button" aria-label="Play" />
                </div>
                <span className="cases__video-time cases__video-time--desktop">Видео 02:47</span>
              </div>

              <div className="cases__content-title-wrap">
                <h2 className="cases__content-title section-title">Петрушенко Евгения</h2>
                <span className="cases__video-time cases__video-time--mobile">Видео 02:47</span>
              </div>

              <div className="cases__content">
                <div className="cases__path-wrap">
                  <span>путь</span>
                  <div className="cases__path-inner">
                    <div className="cases__path-step cases__path-step--one">
                      <div className="cases__path-circle cases__path-circle--grey" />
                      <p>Коуч ICF, доход — 50.000 ₽ в месяц. Чувство неуверенности и ощущение тупика. Желание углубить компетенции и расширить знания.</p>
                    </div>
                    <div className="cases__path-step">
                      <div className="cases__path-circle cases__path-circle--blue" />
                      <p>Коуч ICF, доход — 150.000 ₽ в месяц. Уверенность в себе. Желание углубить компетенции и расширить знания.</p>
                    </div>
                  </div>
                </div>
                <div className="cases__trainings-wrap">
                  <span>Что проходила</span>
                  <ul className="cases__trainings">
                    <li>Иммерсивные учебные сессии по арт-терапии</li>
                    <li>Сессии с психологом онлайн</li>
                    <li>Исцеление раненого внутреннего ребенка</li>
                    <li>Стоп негатив! Группы дерефлексии</li>
                  </ul>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="cases__item swiper-slide">
            <div className="cases__item-inner">
              <div className="cases__video-box">
                <div className="cases__video-wrap">
                  <img className="cases__video-image" src={Cases1Img} alt="Петрушенко Евгения" />
                  <button className="cases__video-btn button-play" type="button" aria-label="Play" />
                </div>
                <span className="cases__video-time cases__video-time--desktop">Видео 02:47</span>
              </div>

              <div className="cases__content-title-wrap">
                <h2 className="cases__content-title section-title">Петрушенко Евгения</h2>
                <span className="cases__video-time cases__video-time--mobile">Видео 02:47</span>
              </div>

              <div className="cases__content">
                <div className="cases__path-wrap">
                  <span>путь</span>
                  <div className="cases__path-inner">
                    <div className="cases__path-step cases__path-step--one">
                      <div className="cases__path-circle cases__path-circle--grey" />
                      <p>Коуч ICF, доход — 50.000 ₽ в месяц. Чувство неуверенности и ощущение тупика. Желание углубить компетенции и расширить знания.</p>
                    </div>
                    <div className="cases__path-step">
                      <div className="cases__path-circle cases__path-circle--blue" />
                      <p>Коуч ICF, доход — 150.000 ₽ в месяц. Уверенность в себе. Желание углубить компетенции и расширить знания.</p>
                    </div>
                  </div>
                </div>
                <div className="cases__trainings-wrap">
                  <span>Что проходила</span>
                  <ul className="cases__trainings">
                    <li>Иммерсивные учебные сессии по арт-терапии</li>
                    <li>Сессии с психологом онлайн</li>
                    <li>Исцеление раненого внутреннего ребенка</li>
                    <li>Стоп негатив! Группы дерефлексии</li>
                  </ul>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </div>
      </Swiper>
    </div>
  </div>
</section>
);

export default Cases;