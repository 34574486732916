import { Link } from 'react-router-dom';

import { RoutePath } from '../../../config/routes';

import 'app/styles/global/global.scss';
import './progs-4-psychologists.scss';

const Progs4Psychologists = () => (
  <section className="progs-4-psychologists" id="progs-4-psychologists">
    <div className="container">
      <div className="progs-4-psychologists__inner">
        <div className="progs-4-psychologists__content">
          <h2 className="progs-4-psychologists__title section-title">
            Программы для психологов
          </h2>
          <p className="progs-4-psychologists__subtitle">
            Станьте лучше как психолог, прокачайте навыки и найдите им
            применение в практике
          </p>
          <Link
            to={RoutePath.REACT_BETTER_PSYCHOLOGICAL}
            state={{ anchor: '#progs-4-psychologists' }}
            className="progs-4-psychologists__link btn btn--violet"
          >
            выбрать свой путь
          </Link>
        </div>
      </div>
    </div>
  </section>
);

export default Progs4Psychologists;
