import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Drawer } from 'antd';

import Close from '1_shared/assets/pictures/close.svg';
import Filters from '1_shared/assets/pictures/Filters.svg';
import { ISpecialistListFilters } from '1_shared/config/interfaces';
import { Button, Typography } from '1_shared/ui';

import FiltersFormUZ from '../../../3_features/FiltersFormUZ/ui/FiltersFormUZ';

import { ISpecialistFiltersProps } from './interfaces/ISpecialistFiltersProps';

import styles from './SpecialistFiltersUZ.module.scss';

const SpecialistFiltersUZ: React.FC<ISpecialistFiltersProps> = ({
  onChange,
}) => {
  const { handleSubmit } = useFormContext<ISpecialistListFilters>();
  const [open, setOpen] = useState<boolean>(false);

  const onClose = () => {
    setOpen(false);
  };

  const handleFormSubmit = (data: ISpecialistListFilters) => {
    const filters = {
      filter: { ...data },
    };
    onChange(filters);
  };

  const handleFieldChange = () => {
    handleSubmit(handleFormSubmit)();
  };

  return (
    <form
      onSubmit={handleSubmit(handleFormSubmit)}
      className={styles.formContainer}
    >
      <div className={styles.fillterContainer}>
        <FiltersFormUZ handleFieldChange={handleFieldChange} />
      </div>

      <div className={styles.mobileFiltersContainer}>
        <Button
          type="secondary"
          className={styles.btnFilters}
          onClick={() => setOpen(true)}
        >
          <img src={Filters} alt="filter" />
          <Typography>Фильтры</Typography>
        </Button>
      </div>
      <Drawer
        placement="left"
        open={open}
        closeIcon={<img src={Close} alt="close" />}
        onClose={onClose}
      >
        <FiltersFormUZ handleFieldChange={handleFieldChange} onSubmit={onClose} />
      </Drawer>
    </form>
  );
};

export default SpecialistFiltersUZ;
